import React, { useEffect } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';
import Home from '../src/component/Home';
import Clients from '../src/component/Clients';
import About from '../src/component/About';
import Contact from '../src/component/Contact';

const App = () => {

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section[id]");
      sections.forEach((section) => {
        const top = section.offsetTop;
        const height = section.offsetHeight;
        if (window.scrollY >= top && window.scrollY < top + height) {
          window.history.replaceState({ id: section.id }, "", `#${section.id}`);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavLinkClick = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar bg="white" expand="lg" className='navbar navbar-expand-sm sticky-top'>
        <Container>
          <Navbar.Brand href="/">RISAR</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="flex-grow-1 justify-content-evenly">
              <Nav.Link onClick={() => handleNavLinkClick("home")}>Home</Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick("clients")}>Our Clients</Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick("about")}>About Us</Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick("contact")}>Contact Us</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div id="home">
        <Home />
      </div>
      <div id="clients">
        <Clients />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <footer className='text-center p-3'>
          ©2022 Copyright: risarcs.com
      </footer>
    </>
  );
};

export default App;
