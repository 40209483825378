import React from 'react'
import { useEffect } from 'react';
import homeDesktopBG from '../images/Home/HomeDesktop.jpeg';
import homeMobileBG from '../images/Home/HomeMobile.jpeg';


const Home = () => {
  const imageUrl = window.innerWidth >= 650 ? homeDesktopBG : homeMobileBG;

  return (
    <div className="bg-image" style={{backgroundImage: `url(${imageUrl})`, height: '100vh', color: 'white', textAlign:'center', fontSize: '50px', fontWeight: 'bold'}}>
      <div className="d-flex align-items-center, position-absolute top-50 start-50 translate-middle">
        RISAR CONSULTANCY SERVICES PRIVATE LIMITED
      </div>
    </div>
  )
}

export default Home