//import Button from 'react-bootstrap/Button';
import React from 'react';
import { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import ClientsDesktop from '../images/Clients/ClientsDesktop.jpeg';
import ClientsMobile from '../images/Clients/ClientsMobile.jpeg';
import ML from '../images/Clients/ML.jpeg';
import EL from '../images/Clients/EL.jpeg';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

const Clients = () => {
  const imageUrl = window.innerWidth >= 650 ? ClientsDesktop : ClientsMobile;

  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <div
            className="position-relative"
            style={{
              backgroundImage: `url(${imageUrl})`,
              height: '300px',
              backgroundSize: 'cover',
              fontSize: '100px',
              color: 'white'
            }}
          >
            <div className="position-absolute top-50 start-50 translate-middle">
              Our Clients
            </div>
          </div>
        </Col>
        </Row>
      <Row className="row justify-content-center">
      <Card className="card me-5 mt-5" style={{ width: '18rem', height: '18rem'}} >
        <Card.Img variant="top" src={ML} style={{height: '200px'}}/>
        <Card.Body>
          <Card.Title>MagicLamp Software</Card.Title>
        </Card.Body>
      </Card>
      <Card className="card me-5 mt-5" style={{ width: '18rem', height: '18rem'}} >
          <Card.Img variant="top" src={EL} style={{height: '200px'}}/>
          <Card.Body>
            <Card.Title>enChoice</Card.Title>
          </Card.Body>
      </Card>
      </Row>
    </Container>
  )
}

export default Clients