import React from 'react';
import { useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import contactDesktop from '../images/Contact/ContactDesktop.jpeg';
import contactMobile from '../images/Contact/ContactMobile.jpeg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const Contact = () => {
  const imageUrl = window.innerWidth >= 650 ? contactDesktop : contactMobile;

  return (
    <Container>
      <Row className="mt-5">
        <Col>
        <div
            className="position-relative"
            style={{
              backgroundImage: `url(${imageUrl})`,
              height: '300px',
              backgroundSize: 'cover',
              fontSize: '100px',
              color:'white'
            }}
          >
            <div className="position-absolute top-50 start-50 translate-middle">
              Contact Us
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center mt-5">
        <Col md={4} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Address</Card.Title>
              <Card.Text>
              <a href="https://www.google.com/maps/place/Aparna+Lake+Breeze/@17.5043466,78.3257322,17z/data=!3m1!4b1!4m5!3m4!1s0x3bcb9284c58230f7:0xef4cf8181846e6e9!8m2!3d17.5043466!4d78.3279262" target="_blank" >G 1005, Aparna Hillpark Lakebreeze Chandanagar - 500050</a>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card>
            <Card.Body>
              <Card.Title>Email</Card.Title>
              <Card.Text>
                  prreddy@risarcs.com
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
