import React from 'react';
import { useEffect } from 'react';
import aboutDesktop from '../images/About/AboutDesktop.jpeg';
import aboutMobile from '../images/About/AboutMobile.jpeg';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

const About = () => {
  const imageUrl = window.innerWidth >= 650 ? aboutDesktop : aboutMobile;

  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <div
            className="position-relative"
            style={{
              backgroundImage: `url(${imageUrl})`,
              height: '300px',
              backgroundSize: 'cover',
              fontSize: '100px',
              color:'white'
            }}
          >
            <div className="position-absolute top-50 start-50 translate-middle">
              About Us
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>About</Card.Title>
              <Card.Text>
                  Founded in 2016, RISAR Consultancy Services has provided high-level software development for clients seeking to enhance their operations.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Body>
              <Card.Title>Director</Card.Title>
              <Card.Text>
                P. Raghothama Reddy
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
